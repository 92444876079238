<template>
  <div>
    <div class="landing" id="home">
      <div class="landing-side">
        <router-link class="arr-down" :to="{ name: 'Home', hash: '#services' }">
          <svg width="50" height="50" viewBox="0 0 25 25" preserveAspectRatio="none" xmlns="http://www.w3.org/2000/svg" fill-rule="evenodd" clip-rule="evenodd">
            <path fill="#fff" d="M11 21.883l-6.235-7.527-.765.644 7.521 9 7.479-9-.764-.645-6.236 7.529v-21.884h-1v21.883z"/>
          </svg>
        </router-link>
        <div class="landing-side-float">
          <h2><span id="profession1">{{ $t('home.webdev') }}</span><span id="profession2">{{ $t('home.consultant') }}</span></h2>
          <h1>{{ $t('home.landingtitle') }}</h1>
          <RouterButton text="home.explorebtn" rHash="#services" rName="Home"/>
        </div>
      </div>
    </div>

    <section class="services split" id="services">
      <div class="margin">
        <div class="left">
          <img src="~@/assets/svg/product_tour.svg"/>
        </div>
        <div class="right">
          <h1 class="preform">{{ $t('home.services.title') }}</h1>
          <h2>{{ $t('home.services.subtitle') }}</h2>
        </div>
      </div>
    </section>

    <section class="skills center" id="skills">
      <div class="margin horizontal-center">
        <h1>{{ $t('home.myskills.title') }}</h1>
        <div class="skill-table" id="skilltable">
          <img src="~@/assets/skills/html.png" alt="HTML" title="HTML">
          <img src="~@/assets/skills/css.png" alt="CSS" title="CSS">
          <img src="~@/assets/skills/js.png" alt="JavaScript" title="JavaScript">
          <img src="~@/assets/skills/vue.png" alt="Vue.js" title="Vue.js">
          <img src="~@/assets/skills/nodejs.png" alt="Node.js" title="Node.js">
          <img src="~@/assets/skills/mongodb.png" alt="MongoDB" title="MongoDB">
          <img src="~@/assets/skills/mysql.png" alt="MySQL" title="MySQL">
          <img src="~@/assets/skills/git.png" alt="Git" title="Git">
        </div>
      </div>
    </section>

    <section class="whyme center" id="whyme">
      <div class="margin">
        <h1>{{ $t('home.whyme.title') }}</h1>
        <WhyMe/>
      </div>
    </section>

    <section class="mywork center" id="mywork">
      <h1>{{ $t('home.mywork.title') }}</h1>
      <div class="clients">
        <div class="clients-item">
          <img src="~@/assets/clients/vision-textil.png" alt="VISION TEXTIL">
          <p>FINANCE SHEETS, PARTNER INTERFACE, USER PAGES</p>
        </div>
        <div class="clients-item">
          <img src="~@/assets/clients/walter-wendel.png" alt="walter wendel">
          <p>CLIENT SHEETS, PROJECT MANAGEMENT, COST PLANNING</p>
        </div>
      </div>
    </section>

    <section class="getintouch center">
      <div class="margin">
        <h1>{{ $t('home.getintouch.title') }}</h1>
        <h2 style="margin-bottom: 20px;">{{ $t('home.getintouch.subtitle') }}</h2>
        <RouterButton text="home.getintouch.btn" rName="Config"/>
      </div>
    </section>
  </div>
</template>

<script>
import RouterButton from '@/components/RouterButton.vue'
import WhyMe from '@/components/WhyMe.vue'

export default {
  name: 'ViewHome',
  components: {
    RouterButton,
    WhyMe
  },
  data() {
    return {
      tick: 1,
      tickStop: false
    }
  },
  mounted() {
    this.$nextTick(this.landingTick)
  },
  unmounted() {
    this.tickStop = true;
  },
  methods: {
    landingTick() {
      this.tickStop = false;
      let ticker = setInterval(() => {
        if (this.tickStop) return clearInterval(ticker)
        if (this.tick == 1) {
          document.getElementById('profession1').style.visibility = 'hidden';
          document.getElementById('profession1').style.position = 'absolute';
          document.getElementById('profession1').style.width = '0';
          document.getElementById('profession1').style.opacity = '0';
          document.getElementById('profession2').style.visibility = 'unset';
          document.getElementById('profession2').style.position = 'initial';
          document.getElementById('profession2').style.width = 'auto';
          document.getElementById('profession2').style.opacity = '1';
          this.tick = 2;
        } else {
          document.getElementById('profession1').style.visibility = 'unset';
          document.getElementById('profession1').style.position = 'initial';
          document.getElementById('profession1').style.width = 'auto';
          document.getElementById('profession1').style.opacity = '1';
          document.getElementById('profession2').style.visibility = 'hidden';
          document.getElementById('profession2').style.position = 'absolute';
          document.getElementById('profession2').style.width = '0';
          document.getElementById('profession2').style.opacity = '0';
          this.tick = 1;
        }

        // easter egg
        let getTime = new Date();
        let hours = (getTime.getHours() > 12) ? getTime.getHours() - 12 : getTime.getHours();
        let minutes = getTime.getMinutes();
        if (hours == '6' && minutes == '9') {
          document.getElementById('skilltable').classList.add('easter-egg');
        }
      }, 3000);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#profession1, #profession2 {
  transition: opacity 1s;
  overflow: hidden;
}
#profession2 {
  visibility: hidden;
  position: absolute;
  opacity: 0;
  width: 0;
}
.landing h1 {
  font-weight: 300;
  letter-spacing: 3px;
  font-size: 3rem;
  margin-bottom: 40px;
  line-height: calc(3rem + 20px);
  opacity: 0.9;
  color: #fff;
}
.landing h2 {
  font-weight: 300;
  letter-spacing: 5px;
  font-size: 1.5rem;
  opacity: 0.9;
  color: #fff;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 20px 0;
  margin-bottom: 20px;
  overflow: hidden;
}
.landing {
  height: 100vh;
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)), url('~@/assets/bg2.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.landing-side {
  position: relative;
  height: 100%;
  width: 600px;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
}
.landing-side-float {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
}
.arr-down {
  text-decoration: none;
  position: absolute;
  z-index: 2;
  left: 0;
  bottom: 0;
  padding: 20px;
  opacity: 0.5;
}
section {
  padding: 80px 40px;
}
section .margin {
  margin: auto;
  max-width: 1320px;
}
section h1 {
  font-size: 2.5rem;
  margin-bottom: 20px;
}
section h2 {
  font-weight: 700;
}
/* section.split,  */section.split .margin {
  display: flex;
}
section.split .left, section.split .right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
section.split .left *:not(img) { text-align: right; }
section.split .right *:not(img) { text-align: left; }
section.split .left img { text-align: center; align-self: center; }
section.split .right img { text-align: center; align-self: center; }

section.split img {
  width: 80%;
}
section.center {
  text-align: center;
}
section.skills {
  padding-top: 0;
}
.horizontal-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.skill-table {
  display: grid;
  grid-template-columns: repeat(auto-fit, 100px);
  width: 100%;
  max-width: 640px;
  gap: 40px 80px;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
  padding: 20px 0;
  transition: filter 1s;
}
.skill-table img {
  height: 100px;
  width: auto;
}
.skill-table.easter-egg img {
  transition: filter 1s;
}
.skill-table.easter-egg img:hover, .skill-table.easter-egg {
  filter: hue-rotate(360deg);
}

section.whyme, section.getintouch {
  background-color: rgba(0, 0, 0, 0.04);
}
.clients {
  display: grid;
  grid-template-columns: repeat(auto-fit, 200px);
  width: 100%;
  max-width: 760px;
  margin: auto;
  gap: 40px 80px;
  justify-content: center;
  align-content: center;
  justify-items: center;
  align-items: center;
}
.clients-item {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100px;
  width: 200px;
}
.clients-item img {
  max-height: 100px;
  max-width: 100%;
  filter: brightness(0%) invert(0.7);
  opacity: 0.7;
}
.clients-item img:hover + p, .clients-item p:hover {
  height: 100px;
  opacity: 0.5;
}
.clients-item p {
  padding-top: 15px;
  font-size: 0.8em;
  font-weight: 700;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: 0.7s height cubic-bezier(.34,.13,.39,1.04), 0.5s opacity cubic-bezier(.34,.13,.39,1.04);
}
section.getintouch {
  min-height: 55vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
section.getintouch h2 {
  font-size: 1.7rem;
}
@media screen and (max-width: 1300px) {
  .landing-side {
    width: 500px;
  }
  .landing-side-float {
    transform: translate(25%, -50%);
  }
}
@media screen and (max-width: 992px) {
  .landing-side {
    width: 450px;
  }
  .landing-side-float {
    transform: translate(20%, -50%);
  }
}
@media screen and (max-width: 800px) {
  section.split, section.split .margin {
    display: block;
    text-align: center;
  }
  section.split img {
    width: 50%;
    margin-bottom: 40px;
  }
  section.split .left *:not(img) { text-align: center; }
  section.split .right *:not(img) { text-align: center; }
}
@media screen and (max-width: 600px) {
  .preform {
    white-space: unset;
  }
  .landing-side {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 40px;
  }
  .landing-side-float {
    transform: unset;
    position: static;
  }
}
</style>
