{
  "general": {
    "send": "Senden",
    "at": "Mit",
    "day": "Tag",
    "hour": "Stunde",
    "plural1": "en",
    "plural2": "n",
    "plural3": "e"
  },
  "statusmsg": {
    "noinput": "Felder dürfen nicht leer sein",
    "unexpected": "Unerwarteter Fehler",
    "successconfig": "Ihre Nachricht wurde erfolgreich gesendet, und ich werde mich schnellstmöglich um Ihr Anliegen kümmern!",
    "invalidemail": "Ungültiges E-Mail Format",
    "toolongconfig": "Eingabe zu lang. E-Mail darf max. 32 Zeichen haben, Ihre Nachricht max. 2000 Zeichen."
  },
  "nav": {
    "home": "Home",
    "services": "Dienste",
    "whyme": "Warum ich?",
    "mywork": "Kunden",
    "signin": "Anmelden",
    "contact": "Kontakt",
    "estimate": "Kostenvorschlag"
  },
  "footer": {
    "navigation": "Navigation",
    "external": {
      "title": "Extern",
      "gitlab": "GitLab Profil",
      "github": "GitHub Profil",
      "email": "E-Mail"
    },
    "more": "Mehr von mir",
    "legal": {
      "copyright": "Alle Rechte vorbehalten",
      "imprint": "Impressum",
      "privacy": "Datenschutz",
      "disclaimer": "Rechtlicher Hinweis"
    }
  },
  "home": {
    "webdev": "Web-Entwickler",
    "consultant": "IT-Berater",
    "landingtitle": "Ihr Unternehmen kann noch mehr.",
    "explorebtn": "Mehr lesen",
    "services": {
      "title": "Was auch immer\nSie brauchen",
      "subtitle": "Sei es eine Website, maßgeschneiderte Software für Ihr Unternehmen, IT-Beratung für große Projekte, oder Workshops für Ihre Mitarbeiter - hier finden Sie alles."
    },
    "myskills": {
      "title": "Meine Fähigkeiten"
    },
    "whyme": {
      "title": "Warum ich?",
      "tabs": [
        {
          "title": "Unkompliziert",
          "info": "Mit korrekter Planung\nist alles möglich"
        },
        {
          "title": "Individuell",
          "info": "Individuell Ihren\nBedürfnissen gemäß"
        },
        {
          "title": "Beratung",
          "info": "Bei Entscheidungen\nimmer an Ihrer Seite"
        }
      ]
    },
    "mywork": {
      "title": "Meine Kunden"
    },
    "getintouch": {
      "title": "Gehe den nächsten Schritt!",
      "subtitle": "Nutzen Sie den Konfigurator zur Preiskalkulation und Einsendung",
      "btn": "Konfigurieren & Preise"
    }
  },
  "configurator": {
    "title": "Konfigurator",
    "descTitle": "Wie funktioniert der Konfigurator?",
    "descDev": "Der Konfigurator für Web-/Softwareentwicklung hat häufig gefragte Eigenschaften aufgelistet, die Sie auswählen können.",
    "descCons": "Für die IT-Beratung wird zwischen einem Stundensatz, welcher bei einer Arbeitszeit von weniger als einem Tag greift, und einem Tagessatz für größere Projekte unterschieden.",
    "tabItem1": "Entwicklung",
    "tabItem2": "IT-Beratung",
    "dev": {
      "properties": {
        "title": "Eigenschaft auswählen",
        "website": "Einfache Website",
        "blog": "Blog",
        "login": "Öffentlicher Nutzer-Login/Registrierung",
        "enterprise": "Enterprise-Lösungen",
        "sheets": "Tabellen",
        "planning": "Planung / Kalender",
        "finances": "Finanzen"
      },
      "estimated1": "Es werden vorraussichtlich",
      "estimated2": "bis",
      "estimated3": "Stunden benötigt, die tatsächliche Zeit kann jedoch abweichen.",
      "estimateddesc": "Diese Schätzung berücksichtigt nicht Ihre individuellen Wünsche.",
      "price": "Kostenvorschlag:",
      "priceHour1": "Mit",
      "priceHour2": "pro Stunde",
      "disclaimer": "Disclaimer: dieser Konfigurator ist eine unverbindliche Einschätzung, nicht der tatsächliche Preis Ihres Projekts. Jedes Projekt kann mehr, oder auch weniger Zeit beanspruchen.",
      "didntfind": "Nicht gefunden wonach Sie suchen?",
      "detailedmsg": "Beschreiben Sie im folgenden Eingabefeld Ihre Wünsche bezüglich Ihres Projekts!",
      "interested": "Interessiert? Schick mir eine Nachricht!",
      "interestedsub": "Doch vorher vielleicht noch ein paar weitere Informationen über Ihr Projekt."
    },
    "consulting": {
      "hourlyPrice": "Stundensatz",
      "hourlyDesc": "pro Stunde für Projekte kürzer als einen Tag",
      "selectHours1": "Menge an Stunden auswählen:",
      "selectHours2": "für",
      "hourlyNotice": "Ab hier gilt der Tagessatz",
      "dailyRate": "Tagessatz",
      "dailyDesc": "pro Tag für Projekte länger als einen Tag und einem Rabatt für länger als eine Woche",
      "selectDays1": "Menge an Tagen auswählen:",
      "selectDays2": "für",
      "dailyNotice1": "Eine Zusammenarbeit, die länger als 14 Tage dauert ",
      "dailyNotice2": "ist möglich",
      "dailyNotice3": ". Kontaktieren Sie mich mit weiteren Informationen und ich werde mit einem Kostenvorschlag auf Sie zurück kommen.",
      "disclaimer": "Disclaimer: dieser Konfigurator ist eine unverbindliche Einschätzung, der tatsächliche Preis wird sich aufgrund von weiteren Kosten wie Anfahrts-/Reisekosten oder durch mehr/weniger benötigter Zeit verändern.",
      "interested":"Interessiert? Schick mir eine Nachricht!",
      "interesteddesc": "Doch vorher vielleicht noch ein paar weitere Informationen über Ihr Projekt."
    }
  },
  "contact": {
    "needestimate": "Erste Preiseinschätzung?",
    "contactdirectly": "Kontaktieren",
    "sendmemail": "Alternativ können Sie mir auch direkt eine E-Mail schicken:",
    "mailbtn": "Mail senden"
  }
}
