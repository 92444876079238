{
  "general": {
    "send": "Send",
    "at": "At",
    "day": "day",
    "hour": "hour",
    "plural1": "s",
    "plural2": "s",
    "plural3": "s"
  },
  "statusmsg": {
    "noinput": "Input cannot be empty",
    "unexpected": "Unexpected error",
    "successconfig": "Your message was sent, and I will get back to you as soon as I can!",
    "invalidemail": "Invalid email format",
    "toolongconfig": "Input too long. Email limit is 32 characters, message limit is 2000 characters."
  },
  "nav": {
    "home": "Home",
    "services": "Services",
    "whyme": "Why me?",
    "mywork": "Clients",
    "signin": "Sign in",
    "contact": "Contact",
    "estimate": "Get an estimate"
  },
  "footer": {
    "navigation": "Navigation",
    "external": {
      "title": "External",
      "gitlab": "GitLab profile",
      "github": "GitHub profile",
      "email": "Email"
    },
    "more": "More of me",
    "legal": {
      "copyright": "All rights reserved",
      "imprint": "Imprint",
      "privacy": "Privacy",
      "disclaimer": "Disclaimer"
    }
  },
  "home": {
    "webdev": "Web-developer",
    "consultant": "IT-Consultant",
    "landingtitle": "Get the most out of your company.",
    "explorebtn": "Start exploring",
    "services": {
      "title": "Get whatever you're \nlooking for",
      "subtitle": "Be it a website, a custom software solution for your company, IT-consulting for large-scale projects, or workshops for your employees – I got you covered."
    },
    "myskills": {
      "title": "My skills"
    },
    "whyme": {
      "title": "Why me?",
      "tabs": [
        {
          "title": "Uncomplicated",
          "info": "Nothing is impossible\nwith the right plan"
        },
        {
          "title": "Individual",
          "info": "Individually tailored to end-user satisfaction"
        },
        {
          "title": "Consulting",
          "info": "By your side for\nevery step of the way"
        }
      ]
    },
    "mywork": {
      "title": "My clients"
    },
    "getintouch": {
      "title": "Let's get in touch!",
      "subtitle": "Head to the configurator for price estimation and hiring",
      "btn": "Configure now"
    }
  },
  "configurator": {
    "title": "Configurator",
    "descTitle": "How does this configurator work?",
    "descDev": "The configurator for web/software development works by simply selecting the properties your project will have.",
    "descCons": "For IT-Consulting, the configurator differentiates between hourly payment for bookings that last less than a day, and daily payment for projects longer than a day.",
    "tabItem1": "Development",
    "tabItem2": "IT-Consulting",
    "dev": {
      "properties": {
        "title": "Select properties",
        "website": "Simple website",
        "blog": "Blog",
        "login": "Public user login/registration",
        "enterprise": "Enterprise solutions",
        "sheets": "Sheets / Tables",
        "planning": "Planning / Calendar",
        "finances": "Finances"
      },
      "estimated1": "Estimated to require between",
      "estimated2": "to",
      "estimated3": "hours to complete, actual outcome may vary.",
      "estimateddesc": "This estimate does not include your custom description.",
      "price": "Price estimate:",
      "priceHour1": "At",
      "priceHour2": "per hour",
      "disclaimer": "Disclaimer: this configurator is a non-binding estimate, not the actual price of your project. Every project varies in the time it requires to make.",
      "didntfind": "Didn't find what you were looking for?",
      "detailedmsg": "Write a detailed message down below, describing your project as you invision it!",
      "interested": "Interested? Send it my way!",
      "interestedsub": "But before you do, write down a few details about your project."
    },
    "consulting": {
      "hourlyPrice": "Hourly price",
      "hourlyDesc": "per hour for projects less than a day",
      "selectHours1": "Select number of hours:",
      "selectHours2": "for",
      "hourlyNotice": "From here on the daily rates apply",
      "dailyRate": "Daily rate",
      "dailyDesc": "per day and a discount for projects longer than a week",
      "selectDays1": "Select number of days:",
      "selectDays2": "for",
      "dailyNotice1": "A collaboration that lasts longer than 14 days ",
      "dailyNotice2": "is possible",
      "dailyNotice3": ". Send me a message and I will come back to you with a price estimate.",
      "disclaimer": "Disclaimer: this configurator is a non-binding estimate, the actual price may differ as it may include things like travel expenses or the required hours or days may change.",
      "interested":"Interested? Send it my way!",
      "interesteddesc": "But before you do, please give a short description of your upcoming project."
    }
  },
  "contact": {
    "needestimate": "Need an estimate?",
    "contactdirectly": "Contact me directly",
    "sendmemail": "Fell free to send me an email at:",
    "mailbtn": "Send mail"
  }
}