<template>
  <div class="tabs">
    <div class="tabs-bar">
      <button id="item1" v-on:click="useTabs($event)" :class="{ 'active': activeTab == 'item1' }">{{ $t('home.whyme.tabs[0].title') }}</button>
      <button id="item2" v-on:click="useTabs($event)" :class="{ 'active': activeTab == 'item2' }">{{ $t('home.whyme.tabs[1].title') }}</button>
      <button id="item3" v-on:click="useTabs($event)" :class="{ 'active': activeTab == 'item3' }">{{ $t('home.whyme.tabs[2].title') }}</button>
    </div>
    <div class="tabs-content">
      <div class="tabs-content-item" :class="{ 'active': activeTab == 'item1' }">
        <div class="left">
          <h1 class="preform">{{ $t('home.whyme.tabs[0].info') }}</h1>
        </div>
        <div class="right">
          <img src="~@/assets/svg/dev.svg"/>
        </div>
      </div>
      <div class="tabs-content-item" :class="{ 'active': activeTab == 'item2' }">
        <div class="left">
          <h1>{{ $t('home.whyme.tabs[1].info') }}</h1>
        </div>
        <div class="right">
          <img src="~@/assets/svg/personalization.svg"/>
        </div>
      </div>
      <div class="tabs-content-item" :class="{ 'active': activeTab == 'item3' }">
        <div class="left">
          <h1 class="preform">{{ $t('home.whyme.tabs[2].info') }}</h1>
        </div>
        <div class="right">
          <img src="~@/assets/svg/talk.svg"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'WhyMe',
  data() {
    return { activeTab: 'item1' }
  },
  methods: {
    useTabs(event) {
      this.activeTab = event.currentTarget.id;
    }
  }
}
</script>

<style scoped>
.tabs {
  margin: auto;
  max-width: 1000px;
  margin-top: 40px;
}
.tabs-bar {
  display: flex;
}
.tabs-bar button {
  width: 100%;
  border: 0;
  outline: 0;
  font-family: 'Montserrat', sans-serif;
  font-size: 1.3rem;
  padding: 15px 25px;
  font-weight: 500;
  cursor: pointer;
  background-color: transparent;
  border-bottom: 3px solid rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}
.tabs-bar button.active {
  border-color: #3742fa;
}
.tabs-content-item {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: opacity 0.3s;
  padding: 40px 40px 0 40px;
  display: flex;
  height: 350px;
  width: 0;
}
.tabs-content-item.active {
  visibility: visible;
  opacity: 1;
  position: initial;
  width: auto;
}
.tabs-content-item .left, .tabs-content-item .right {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
.tabs-content-item h1 {
  font-size: 2rem;
}
.tabs-content-item img {
  max-width: 70%;
  text-align: center;
}
@media screen and (max-width: 800px) {
  .tabs-content-item {
    flex-direction: column-reverse;
    height: auto;
  }
  .tabs-content-item .right {
    margin-bottom: 1em;
  }
  .tabs-content-item img {
    max-width: 50%;
  }
  section.split .left *:not(img) { text-align: center; }
  section.split .right *:not(img) { text-align: center; }
}
@media screen and (max-width: 620px) {
  .tabs-bar {
    flex-direction: column;
  }
  .preform {
    white-space: unset;
  }
}
</style>