<template>
  <div class="home">
    <ViewHome/>
  </div>
</template>

<script>
import { useHead } from '@vueuse/head'
// @ is an alias to /src
import ViewHome from '@/components/ViewHome.vue'

export default {
  name: 'HomeView',
  components: {
    ViewHome
  },
  setup () {
    useHead({
      title: 'developedByFlip - Philipp Richert',
      meta: [
        {
          name: 'description',
          content: 'Softwarelösungen als Web-Anwendung - optimal für das Home Office. Ihre IT-Beratung für das nächste Projekt.'
        }
      ]
    })
  }
}
</script>
