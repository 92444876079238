<template>
  <router-link :to="{ name: rName, hash: rHash }">{{ $t(text) }}</router-link>
</template>

<script>
export default {
  name: 'RouterButton',
  props: {
    rName: String,
    rHash: String,
    text: String
  }
}
</script>

<style scoped>
a {
  text-decoration: none;
  background-color: #3742fa;
  color: #fff;
  display: inline-block;
  font-family: 'Montserrat', sans-serif;
  font-size: 100%;
  padding: 15px 25px;
  font-weight: 700;
  transition: background-color 0.3s;
}
a:hover {
  background-color: #323be2;
}
</style>