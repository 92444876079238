import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import { createHead } from '@vueuse/head'
//import { store } from './vuex'


// createApp(App).use(router).mount('#app')
const app = createApp(App)
const head = createHead()
// DEVELOPMENT: app.config.globalProperties.apihost = 'http://localhost:3000/developedbyflip'
// PRODUCTION:
app.config.globalProperties.apihost = 'https://watson.byflip.dev/developedbyflip'
app.use(i18n)
//app.use(store)
app.use(router)
app.use(head)
app.mount('#app')
